.slider-wrapper {
    height: 100%
}

.carousel-slider {
    height: 100%
}

.slider {
    height: 100%
}